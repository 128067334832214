import React from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Copyright } from '../../Components/Copyright';
import IntroContainer from '../../Container/IntroContainer';

import {
	isLoggedIn
} from '../../utils';


function Intro() {
	const [loggedIn, setIsLoggedIn] = React.useState(isLoggedIn());

	return (
		<Grid container style={{ backgroundSize: 'cover'}}>
			{
				(!isLoggedIn() || !loggedIn) && <Redirect to='/login' />
			}
			<Grid item xs={12}>
				<Grid container style={{minHeight: '100vh' }}>
					<Grid item xs={12}>
						<IntroContainer />
					</Grid>
					<Grid item xs={12}>
						<Copyright sx={{ pt: 4 }} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Intro;
