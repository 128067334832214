import * as React from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { GoThumbsup, GoThumbsdown } from 'react-icons/go';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import './typing.scss';
import { queryAPI } from '../../Api';
import socketIOClient from 'socket.io-client'
import {
	URL_SOCK,
	ACCESS_TOKEN,
	MODEL_NAME,
	MODEL_URL
} from '../../config';
import {
	ITEM_COMPONENT,
	SECONDARY_COLOR,
	PRIMARY_TEXT_COLOR,
	USER_UTTERANCE_BUTTON,
	SYSTEM_UTTERANCE_BUTTON,
	PRIMARY_COLOR,
	THUMBS_DOWN,
	THUMBS_INIT
} from '../../constant';
import {
	Notification
  } from '../../Components/Notification';
import {
	getCurrentUserDetails,
} from '../../redux/User/utils';

const swicthUserVal = {
	"USER": "left",
	"SYSTEM": "right"
}

const useStyles = makeStyles({
	rightToolbar: {
		marginLeft: "auto",
	},
	viewButton: {
		textTransform: "none"
	}
})

var currentSocket = socketIOClient(URL_SOCK+"?user_id="+localStorage.getItem('id'), {
	extraHeaders: {
	  'Authorization': ACCESS_TOKEN
	},
	reconnectionDelayMax: 180000,
  })

export function disconnect_socket() {
	currentSocket.disconnect()
	close_all_sessions()
	console.log("In logout... \nIs connected: ["+currentSocket.connected+"]. ID: ["+currentSocket.id+"]")
}

const close_all_sessions = () => {
	const req_data = {
		url: MODEL_URL+"/sessions?user_id="+localStorage.getItem('id'),
		method: "delete",
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Authorization': ACCESS_TOKEN
		},
	}
	axios.request(req_data)
	.then(res => res)
	.catch(err => err)
}


function ChatBotContainer() {
	const classes = useStyles()
	const [textFieldValue, setTextFieldValue] = React.useState("");
	const [utterances, setUtterance] = React.useState([]);
	const [clickedUp, setClickedUp] = React.useState([]);
	const [clickedDown, setClickedDown] = React.useState([]);
	const [isTyping, setIsTyping] = React.useState(false);
	const [utterID, setUtterID] = React.useState([]);
	const [isEmptyDialogue, setIsEmptyDialogue] = React.useState(true);
	const [isResetConv, setIsResetConv] = React.useState(false);
	const [isAccessTokenProblem, setIsAccessTokenProblem] = React.useState(false);
	const [session_id, setSessionID] = React.useState("")
	const [lastSystemResponse, setLastSystemResponse] = React.useState("")

	React.useEffect(()=> {
		close_all_sessions()
		handleReset()
		currentSocket.connect()
		setIsResetConv(false)
		currentSocket.on('connect', (data) => {			
			if (data !== undefined) {
				let session_id = data['session_id'];
				setSessionID(session_id)
				console.log("session_id: " + session_id)
			}
			console.log("In connect... \nIs connected: ["+currentSocket.connected+"]. ID: ["+currentSocket.id+"]")
		  })
	}, [isResetConv])

	React.useEffect(()=> {
		setIsEmptyDialogue(true)
		// reset counter
		const user_data = {reset_counter: true,
			user_id: localStorage.getItem('id')}
		queryAPI('/utterance', user_data, 'POST')
		return () => {
			currentSocket.disconnect();
		}
	}, [])

	React.useEffect(()=> {
		// scroll down to the last message
		if (utterances !== undefined) {
			let last_message = utterances[utterances.length - 1]
			if (last_message !== undefined) {
				var last = document.querySelectorAll(`[id^="frame_id_${last_message['id']}"]`)
				if (last[0] !== null) {last[0].scrollIntoView()}
			}
		}
	})

	React.useEffect(()=> {
		// handle messages comming from the model
		currentSocket.on('chat', (data) => {			
			if (!isEmptyDialogue) {
				if (data['system_response'] != lastSystemResponse) {
					setLastSystemResponse(data['system_response'])
					setUtterance(utterances => [...utterances,
						{id: utterances.length, speaker: 'SYSTEM', utterance: data['system_response']}
					])
				}
			}
			setIsTyping(false)
		})
	}, [isEmptyDialogue])

	React.useEffect(()=> {
		// handle messages comming from the model
		currentSocket.on('chat', (data) => {
			
			if (!isEmptyDialogue) {
				if (data['system_response'] != lastSystemResponse) {
					setLastSystemResponse(data['system_response'])
					// send data for storage
					const user_data = {
						user_id: localStorage.getItem('id'),
						message: data['system_response'],
						speaker: 'system',
						session_id: session_id,
						model_name: MODEL_NAME,
					}
					queryAPI('/utterance', user_data, 'POST').then(data => {
						if (data.status === 200) {
							let utter_id = data.data.utter_id
							setUtterID(utterID => [...utterID,
								{order: utterID.length, id: utter_id}
							])}
					})
				}
			}
		})
	}, [isEmptyDialogue])
	
	function setText() {
		if (currentSocket.id !== undefined) {
			currentSocket.emit('chat', {
				"userutter": textFieldValue, 
				"model_name": MODEL_NAME,
				"session_id": session_id})
			setUtterance([...utterances,
				{id: utterances.length, speaker: 'USER', utterance: textFieldValue}
			])
			setTextFieldValue("")
			setIsTyping(true)
			setIsEmptyDialogue(false)
			setLastSystemResponse("")
			// send data for storage
			const user_data = {
				user_id: localStorage.getItem('id'),
				message: textFieldValue,
				speaker: 'user',
				session_id: session_id,
				model_name: MODEL_NAME
			}
			queryAPI('/utterance', user_data, 'POST').then(data => {
				if (data.status === 200) {
					let utter_id = data.data.utter_id
					setUtterID(utterID => [...utterID,
						{order: utterID.length, id: utter_id}
					])
				}})
		} else { 
			setIsAccessTokenProblem(true)
			setTextFieldValue("")
			setTimeout(() => setIsAccessTokenProblem(false), 1000); }
	}

	const handleReset = () => {
		setUtterance([]);
		setClickedDown([]);
		setClickedUp([]);
		setUtterID([]);
		setLastSystemResponse("")
		currentSocket.disconnect()
		close_all_sessions()
		currentSocket = socketIOClient.connect(URL_SOCK+"?user_id="+localStorage.getItem('id'), {
			extraHeaders: {
			  'Authorization': ACCESS_TOKEN
			}
		})
		setIsResetConv(true)
		setIsEmptyDialogue(true)
		// reset counter
		const user_data = {reset_counter: true,
			user_id: localStorage.getItem('id')}
		queryAPI('/utterance', user_data, 'POST')
	}

	function handleTextFieldChange(e) {
		setTextFieldValue(e.target.value)
	}

	function sendNewMessageWithEnter(event) {
		if (event.key === 'Enter') {
			if (event.target.value !== "") {
				event.preventDefault();
				setText();
			}
		}
	}

	function get_utter_id(index) {
		if (utterID) {
			const result = utterID.find(utter => utter.order === index)
			return result.id
		}
		return 
	}

	function handleThumbsClick(orientation, index) {
		const data = {
			user_id: localStorage.getItem('id'),
			utter_id: get_utter_id(index),
			key: "thumbsup",
			value: orientation}
		
		if (orientation==='up'){ 
			if (!clickedDown.includes(index)) {
				if (!clickedUp.includes(index)) {
					setClickedUp([...clickedUp, index])
					queryAPI('/utterance', data, 'PUT')
				} else {
					setClickedUp(clickedUp.filter(item => item !== index))
					data['value'] = null
					queryAPI('/utterance', data, 'PUT')
				}
			}
		}

		if (orientation==='down'){ 
			if (!clickedUp.includes(index)) {
				if (!clickedDown.includes(index)) {
					setClickedDown([...clickedDown, index])
					queryAPI('/utterance', data, 'PUT')
				} else {
					setClickedDown(clickedDown.filter(item => item !== index))
					data['value'] = null
					queryAPI('/utterance', data, 'PUT')
				}
			}
		}		
	}

	return (
		<React.Fragment>

			<Grid container justifyContent="center" alignItems="center" spacing={0} style={{ alignItems: 'center', marginTop: "10px" }}>
				{/* <Grid item xs={3}></Grid> */}
				{isAccessTokenProblem &&
					<Notification isOpen={true} severity="error" message={"Unauthorized access to chat"} />
				}
				<Grid item xs={5}>
					<ITEM_COMPONENT>
						<Grid container spacing={1} data-testid="messages-area"
							style={{
							flexGrow: 1,
							overflow: 'auto',
							minHeight: '70vh',
							maxHeight: '50vh',
						}}>
							<Grid item xs={12}>
								{
									utterances.map((chat, index) => (
										<Grid
											id={`frame_id_${chat['id']}`}
											container
											key={index}
											spacing={1}
											sx={{ float: chat["speaker"] === 'SYSTEM' ? 'right' : 'left' }}
										>
											<Grid
												item xs={12}
												sx={{
													ml: chat['speaker'] === 'SYSTEM' ? 11 : 0,
													mr: chat['speaker'] === 'USER' ? 11 : 0,
												}}>
												<Button
													className={classes.viewButton}
													size={'large'}
													variant="contained"
													key={index}
													fullWidth={false}
													sx={{
														justifyContent: chat["speaker"] === 'SYSTEM' ? swicthUserVal['SYSTEM'] : swicthUserVal['USER'],
														width: 1,
														mt: 1,
														mb: 1,
														textTransform: "none",
														color: PRIMARY_TEXT_COLOR,
														border: chat["speaker"] === 'SYSTEM' ? USER_UTTERANCE_BUTTON : SYSTEM_UTTERANCE_BUTTON,
														bgcolor: chat["speaker"] === 'SYSTEM' ? USER_UTTERANCE_BUTTON : SYSTEM_UTTERANCE_BUTTON,
														float: chat["speaker"] === 'SYSTEM' ? swicthUserVal['SYSTEM'] : swicthUserVal['USER'],
														"&:hover": {
															bgcolor: chat["speaker"] === 'SYSTEM' ? USER_UTTERANCE_BUTTON : SYSTEM_UTTERANCE_BUTTON,
														},
													}}
													style={{
														'borderTopLeftRadius': chat["speaker"] === 'USER' ? '12px' : '0px',
														'borderTopRightRadius': chat["speaker"] === 'SYSTEM' ? '12px' : '0px',
														'borderBottomLeftRadius': '12px',
														'borderBottomRightRadius': '12px',
														'textAlign': 'right',
													}}
												>
													{
														<React.Fragment> {chat["utterance"]} </React.Fragment>
													}
												{(chat['speaker']=='SYSTEM') &&
												<Grid style={{ flex: 1 }}>
													<GoThumbsup 
														style={{ flex: 1, color: clickedUp.includes(index) ? PRIMARY_COLOR : THUMBS_INIT, marginLeft:'5px' }}
														onClick = {() => handleThumbsClick("up", index)}
													/>
													<GoThumbsdown 
														style={{ flex: 1, color: clickedDown.includes(index) ? THUMBS_DOWN : THUMBS_INIT, marginLeft:'5px' }}
														onClick = {() => handleThumbsClick("down", index)}
													/>
												</Grid>}
												</Button>
											</Grid>
											<br />
										</Grid>
									))
								}
							</Grid>
						</Grid>

						{	isTyping &&
							<Grid className="typing">
								<span></span>
								<span></span>
								<span></span>
							</Grid>
						}
						
						<TextField
							data-testid="input-area"
							sx={{
								mt: 2
							}}
							fullWidth
							autoFocus
							id="inputText"
							placeholder={isEmptyDialogue ? "Tell me how you are feeling?" : ""}
							value={textFieldValue}
							onChange={handleTextFieldChange}
							onKeyPress={(e) => sendNewMessageWithEnter(e)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title="reset">
											<IconButton aria-label="reset" onClick={handleReset}
												sx={{
													bgcolor: "#188BD8", color: SECONDARY_COLOR,
													boxShadow: 1, border: '1px solid', borderColor: "#188BD8",
													"&:hover": {
														bgcolor: "#188BD8", color: SECONDARY_COLOR,
														boxShadow: 1, border: '1px solid', borderColor: "#188BD8",
													}
												}}
											>
												<RestartAltIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title="send">
											<IconButton aria-label="send" onClick={setText}
												sx={{
													bgcolor: "#188BD8", color: SECONDARY_COLOR,
													boxShadow: 1, border: '1px solid', borderColor: "#188BD8",
													"&:hover": {
														bgcolor: "#188BD8", color: SECONDARY_COLOR,
														boxShadow: 1, border: '1px solid', borderColor: "#188BD8",
													}
												}}
											>
												<SendIcon />
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}} />
					</ITEM_COMPONENT>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

ChatBotContainer.propTypes = {
	changeSwitchValue: PropTypes.func,
	user: PropTypes.object
};


const mapStateToProps = (state, props) => {
	return {
		user: getCurrentUserDetails(state['user']),
	}
}

export default connect(mapStateToProps, null)(ChatBotContainer);
