import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const PRIMARY_COLOR = "#003588";
export const SECONDARY_COLOR = "#FFFFFF";
export const CANCEL_BUTTON_COLOR = '#E5E4E2';
export const PRIMARY_TEXT_COLOR = "#000000";
export const USER_UTTERANCE_BUTTON = "#e0e0eb";
export const SYSTEM_UTTERANCE_BUTTON = "#e6f3ff";
export const THUMBS_INIT = "#7A7A7A";
export const THUMBS_DOWN = "#db2127";
export const SLIDER_INDICATOR_COLOR = "#70707026";
export const SLIDER_ACTIVE_INDICATOR_COLOR = "#70707062";

export const CARD_MAX_WIDTH = {
  maxWidth: 900
}

export const ITEM_COMPONENT = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));
