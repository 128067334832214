import {
    SET_LOGIN_STATUS,
    SET_ERROR,
    SET_NOTIFICATION_MSG,
    SET_NOTIFICATION_ON,
    SET_NOTIFICATION_SEVERITY,
    SET_CURRENT_SLIDE_NAME,
    SET_USER,
} from './user.type';


const initialState = {
    isSuccessfullLogin: true,
    notificationMsg: '',
    notificationSeverity: '',
    isNotificationON: '',
    currentSlideName: '',
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGIN_STATUS:
            return {...state, 'isSuccessfullLogin': action.payload}
        case SET_ERROR:
            return{...state, 'isError': true, 'error_message': action.payload.error}
        case SET_NOTIFICATION_MSG:
                return {...state, 'notificationMsg': action.payload}
        case SET_NOTIFICATION_ON:
            return {...state, 'isNotificationON': action.payload}
        case SET_NOTIFICATION_SEVERITY:
            return {...state, 'notificationSeverity': action.payload}
        case SET_CURRENT_SLIDE_NAME:
            return{...state, 'currentSlideName': action.payload}
        case SET_USER:
            return{...state, 'currentUser': action.payload}
        default: 
            return state;
    }
}

export default UserReducer;
