import {
    setNotificationON,
    setNotificationSeverity
} from './redux/User/user.action'

export const isLoggedIn = () => {
    let auth_token = localStorage.getItem('auth_token');
    if (auth_token && auth_token !== 'undefined') {
        return true
    }
    return false
}

export const getUserToken = () => {
    const auth_token = localStorage.getItem('auth_token');
    if (auth_token) return auth_token
    else return ''
}

export const isAPIError = (response, dispatch=false) => {
    if (response.status !== 200) {
        setNotificationON(true)
        setNotificationSeverity('error')
        if (dispatch) {
            dispatch({ type: "DISABLE_LOADING" })
        }
        return true
    } else return false
}
