import React from 'react';
import './App.css';
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import routes from "./routes";
import { Provider } from "react-redux";
import store from './redux/store';
import {
  isLoggedIn
} from './utils';
function App() {
  return (
    <div className="App" style={{minHeight: "100%"}}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            {routes.map((prop, key) => (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
              ))}
            <Redirect from="/" to={isLoggedIn() ? "/intro" : "/login"} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
