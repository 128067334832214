import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChatBotContainer from '../../Container/ChatBotContainer';
import { Notification } from '../../Components/Notification';
import { Copyright } from '../../Components/Copyright';
import { Loader } from '../../Components/Loader';
import AppBarContainer from '../../Container/AppBarContainer';
import { Redirect } from 'react-router-dom';
import { disconnect_socket } from '../../Container/ChatBotContainer';

import Grid from '@mui/material/Grid';
import {
  isLoggedIn
} from '../../utils';
import {
  setNotificationON,
  setNotificationMsg,
  setNotificationSeverity,
  getCurrentUser
} from '../../redux/User/user.action';
import {
  PRIMARY_COLOR
} from '../../constant';
import {
	queryAPI
} from "../../Api/index";
import {
  getCurrentUserDetails,
} from '../../redux/User/utils';

const drawerWidth = 280;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      // whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent(
  { isLoading,
    isNotificationON,
    notificationMsg,
    notificationSeverity,
    getCurrentUser,
  }) {
  const [open, setOpen] = React.useState(true);
  const [loggedIn, setIsLoggedIn] = React.useState(isLoggedIn());
  useEffect(() => {
    getCurrentUserDetails()
  }, [ getCurrentUser ])

  const toggleDrawer = () => {
    setOpen(!open);
  };
  
  const logout = () => {
    queryAPI('/token', '', 'DELETE')
    localStorage.clear()
    setIsLoggedIn(false)
    disconnect_socket()
  }

  function DashboardComponent() {
    
    return (
      <ChatBotContainer /> 
    )
  }
  
  const title = "Affiniti Therapy Model v0.1.0"
  const description = "A therapy model designed to deliver a variety of Cognitive Behavioural Therapy (CBT) and other evidence-based therapeutic techniques."
  return (
    <ThemeProvider theme={mdTheme}>
      {
        (!isLoggedIn() || !loggedIn) && <Redirect to='/login' />
      }
      <Box sx={{ display: 'flex' }}>
        <AppBarContainer
          open={open}
          toggleDrawer={toggleDrawer}
          isDashBoardPage={true}
          logout={logout}
        />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer} style={{ color: PRIMARY_COLOR }}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Grid container sx={{ width: '80%', ml: "8%"}}>
            <Grid item xs={12} sx={{mt: 4}}>
              <Typography
                variant="h5"
                style={{ fontWeight: 800, overflowWrap: 'break-word', textAlign: 'left'}}
              >{title}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
              <Typography
                variant="subtitle2"
                style={{  fontSize: '10px', wordWrap: 'break-word', textAlign: 'left'}}
              >{description}
              </Typography>
            </Grid>
          </Grid>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
          style={{ backgroundImage: `url("/images/background.png")`, backgroundSize: 'cover' }}
          >
          <Toolbar />
          <Container maxWidth="lg" sx={{
            mt: 0,
            mb: 0,
          }}>
            {
              isLoading ?
                <Loader /> :
                <React.Fragment>
                  <DashboardComponent />
                  { <Copyright sx={{ pt: 4 }} />}
                </React.Fragment>
            }
          </Container>
          {isNotificationON &&
            <React.Fragment>
              <Notification isOpen={isNotificationON} message={notificationMsg} severity={notificationSeverity} />
            </React.Fragment>
          }
        </Box>
      </Box>
    </ThemeProvider>
  );
}

DashboardContent.propTypes = {
  isLoading: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ]),
  setNotificationMsg: PropTypes.func,
  setNotificationSeverity: PropTypes.func,
  setNotificationON: PropTypes.func,
  isNotificationON: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ]),
  notificationMsg: PropTypes.string,
  notificationSeverity: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.user.isLoading,
    isNotificationON: state.user.isNotificationON,
    notificationMsg: state.user.notificationMsg,
    notificationSeverity: state.user.notificationSeverity,
    user: getCurrentUserDetails(state['user']),
  };
};

const mapDispatchToProps = {
  setNotificationON,
  setNotificationMsg,
  setNotificationSeverity,
  getCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContent);
