import * as React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import {  PRIMARY_TEXT_COLOR } from '../../constant';
import {
	getCurrentUserDetails,
} from '../../redux/User/utils';

function AppBarMenu({userDetails}) {
    let history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [firstName, setFirstName] = React.useState(
        localStorage.getItem("first_name"));

    return (
        <div>
            <Button
                id="positioned-button"
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Avatar alt="User name" src="/images/user.png" data-testid = "profile-img-test-id" />
                <Typography
                variant="subtitle1"
                sx={{ color: PRIMARY_TEXT_COLOR, mt: "7px", ml: "4px" }}>
                {firstName && firstName.slice(0, 4)} 
                </Typography>
            </Button>
            <Menu
                id="positioned-menu"
                aria-labelledby="positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => history.push("/profile")}>Profile</MenuItem>
            </Menu>
        </div>
    );
}

AppBarMenu.propTypes = {
    userDetails: PropTypes.array,
}

const mapStateToProps = (state, props) => {
    return {
		userDetails: getCurrentUserDetails(state['user']),
    }
}

export default connect(mapStateToProps, null)(AppBarMenu);