import { queryAPI } from "../../Api/index";
import { isAPIError } from '../../utils';

export const setLoginStatus = (isLoginSuccess) => (
    (dispatch) => {
        dispatch({ type: "SET_LOGIN_STATUS", payload: isLoginSuccess })
    }
)

export const setNotificationMsg = (msg) => (
    (dispatch) => {
        dispatch({ type: "SET_NOTIFICATION_MSG", payload: msg })
        setTimeout(() => {
            dispatch({ type: "SET_NOTIFICATION_MSG", payload: '' })
        }, 2000)
    }
)

export const setNotificationON = (data) => (
    (dispatch) => {

        dispatch({ type: "SET_NOTIFICATION_ON", payload: data })
        setTimeout(() => {
            dispatch({ type: "SET_NOTIFICATION_ON", payload: false })
        }, 2000)
    }
)

export const setNotificationSeverity = (data) => (
    (dispatch) => {
        dispatch({ type: "SET_NOTIFICATION_SEVERITY", payload: data })
        setTimeout(() => {
            dispatch({ type: "SET_NOTIFICATION_SEVERITY", payload: '' })
        }, 2000)
    }
)

export const setUser = (user_data) => (
    (dispatch) => {
        localStorage.setItem("first_name", user_data['first_name'])        
        localStorage.setItem("last_name", user_data['last_name'])        
        localStorage.setItem("email", user_data['email'])        
        localStorage.setItem("id", user_data['id'])        
        localStorage.setItem("organisation", user_data['organisation'])        
        dispatch({ type: "first_name", payload: user_data })
    }
)

export const getCurrentUser = () => (
	(dispatch) => {
		dispatch({ type: "SET_LOADING" })
		queryAPI('/token', {}, 'get')
			.then(res => {
				if (isAPIError(res)) return
				res = res['data']
				dispatch({ type: "SET_USER", payload: res['user'] })
				dispatch({ type: "DISABLE_LOADING" })
			})
	}
)
