import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Copyright } from '../../Components/Copyright';
import AppBarContainer from '../../Container/AppBarContainer';
import OutlinedInput from '@mui/material/OutlinedInput';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Notification
} from '../../Components/Notification';
import {
    setNotificationMsg,
} from '../../redux/User/user.action';
import {
    isLoggedIn
} from '../../utils';
import {
    queryAPI
} from '../../Api';
import {
    SECONDARY_COLOR,
    CARD_MAX_WIDTH,
    PRIMARY_COLOR
} from '../../constant';

function getUser() {
    var user = {'first_name': localStorage.getItem("first_name")}
    user['last_name'] = localStorage.getItem("last_name")
    user['email'] = localStorage.getItem("email")
    user['id'] = localStorage.getItem("id")
    user['organisation'] = localStorage.getItem("organisation")
    return user
}

function Profile({ setNotificationMsg, notificationMsg }) {
    const [loggedIn, setIsLoggedIn] = React.useState(isLoggedIn());
    const [isLoading, setIsLoading] = React.useState(false)
    const [isUpdatePasswordClick, setIsUpdatePasswordClick] = React.useState(false)
    const [oldPassword, setOldPassword] = React.useState("")
    const [newPassword, setNewPassword] = React.useState("")
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("")
    const [errorInUpdate, setErrorInUpdate] = React.useState(false)
    const [successInUpdate, setSuccessInUpdate] = React.useState(false)
    const [userDetails, setUserDetails] = React.useState(getUser())

    const logout = () => {
        queryAPI('/token', '', 'DELETE')
        localStorage.clear()
        setIsLoggedIn(false)
    }
    const updatePassword = () => {
        setIsLoading(true)
        setIsUpdatePasswordClick(false)
        const data = {
            "user_id": userDetails['id'],
            "password": oldPassword,
            "new_password": confirmNewPassword,
        }
        queryAPI('/user', data, 'PUT')
            .then(response => {
                if (response && response['status'] && response.status === 200) {
                    setSuccessInUpdate(true)
                }
                else if (response.response && response.response.status !== 200) {
                    setNotificationMsg(response.response.data)
                    setErrorInUpdate(true)
                }

                setIsLoading(false)
                setOldPassword("")
                setNewPassword("")
                setConfirmNewPassword("")
                return
            })

    }

    return (
        <Grid container style={{ backgroundImage: `url("/images/background.png")`, backgroundSize: 'cover' }}>
            {
                (!isLoggedIn() || !loggedIn) && <Redirect to='/login' />
            }
            <Grid item xs={12}>
                <AppBarContainer logout={logout} />
            </Grid>
            {errorInUpdate &&
                <Notification
                    severity="error"
                    isOpen={true}
                    message={notificationMsg || "Password update Failed"} />}
            {successInUpdate &&
                <Notification
                    severity="success"
                    isOpen={true}
                    message={"Successfully Updated Password"} />
            }
            <Grid item xs={12}>
                <Grid container style={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="center" direction="row">
                            <Grid
                                item
                                xs={12}
                                justifyContent="flex-start"
                                style={{ margin: '2%' }}
                            >
                                <Typography
                                    variant="h3"
                                    style={{ color: SECONDARY_COLOR }}>
                                    Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={CARD_MAX_WIDTH} style={{ minWidth: "60vw", marginLeft: "20%", minHeight: "10vh", marginRight: "20%" }}>
                                    <CardContent sx={{ m: 5 }}>
                                        <Grid container spacing={2}>
                                            <Grid xs={6} >
                                                <img src="/images/user_profile.png" alt="user_profile" />
                                            </Grid>
                                            {
                                                userDetails &&
                                                <Grid xs={6} >
                                                    <Grid container spacing={2}>
                                                        <Grid xs={6}  sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                Name:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ float: "left", mb: 1 }}>
                                                                {userDetails["first_name"] + " " + userDetails["last_name"]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid xs={6} sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                Email:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ float: "left", mb: 1 }}>
                                                                {userDetails["email"]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid xs={6} sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                Organisation:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} sx={{ mt: 4 }}>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ float: "left", mb: 1 }}>
                                                                {userDetails["organisation"]}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        !isUpdatePasswordClick &&
                                                        <Grid container spacing={2}>
                                                            <Grid xs={6} sx={{ mt: 4 }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                    Password:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid xs={6} sx={{ mt: 4 }}>

                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{ float: "left", mb: 1 }}>
                                                                    *********
                                                                </Typography>

                                                                <a href="#" onClick={() => setIsUpdatePasswordClick(true)}>Update Password</a>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        isUpdatePasswordClick &&
                                                        <React.Fragment>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                        Old Password:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <OutlinedInput
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        id="slot_name"
                                                                        name="slot_name"
                                                                        autoComplete="slot_name"
                                                                        value={oldPassword}
                                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                                        autoFocus
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container spacing={2}>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                        New Password:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <OutlinedInput
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        id="slot_name"
                                                                        name="slot_name"
                                                                        autoComplete="slot_name"
                                                                        value={newPassword}
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{ float: "left", mb: 1, fontWeight: 'bold', ml: 6 }}>
                                                                        Confirm Password:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6} sx={{ mt: 4 }}>
                                                                    <OutlinedInput
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        id="slot_name"
                                                                        name="slot_name"
                                                                        autoComplete="slot_name"
                                                                        value={confirmNewPassword}
                                                                        error={confirmNewPassword && confirmNewPassword !== newPassword}
                                                                        helperText="new password and confirm password are not same"
                                                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <LoadingButton
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                loading={isLoading}
                                                                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px" }}
                                                                disabled={!newPassword || !confirmNewPassword || !oldPassword || (confirmNewPassword && confirmNewPassword !== newPassword)}
                                                                onClick={() => updatePassword()}
                                                            >
                                                                Update
                                                            </LoadingButton>
                                                        </React.Fragment>
                                                    }

                                                </Grid>
                                            }

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

Profile.propTypes = {
    setNotificationMsg: PropTypes.func,
    notificationMsg: PropTypes.string,
}

const mapStateToProps = (state, props) => {
    return {
        notificationMsg: state.user.notificationMsg,
    }
}

const mapDispatchToProps = {
    setNotificationMsg,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
