import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { IntroSlider } from '../Slider';

function IntroContainer() {
  return (
    <Grid container style={{ minHeight: '100vh' }}>

      <Grid item xs={7} >
        <Grid container>
          <Grid item xs={12}>
            <div style={{ marginLeft: '15%', float: 'left', marginTop: '5%' }}>
              <img src="/images/affiniti.svg"
                width="90%"
                alt="affinti_logo"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: 18, ml: 15, mr: 6 }}>
              <IntroSlider />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}
        style={{ backgroundImage: `url("/images/intro_background.png")`, backgroundSize: 'cover'}}
      >
      </Grid>
    </Grid>
  );
}

export default IntroContainer;
