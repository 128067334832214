import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Link, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { Redirect } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  PRIMARY_COLOR,
  SLIDER_INDICATOR_COLOR,
  SLIDER_ACTIVE_INDICATOR_COLOR
} from '../../constant';

function Item(props) {
  return (
      <Grid container style={{ minHeight: '10vh', maxHeight: '60vh' }}>
                
          <Typography id="slider_name" variant="h2" sx={{ float: "left" }}>
            {props.item.name}
          </Typography>
          
          <Typography variant="subtitle1" align = "justify" sx={{ position: 'absolute', top: '50px', mt: 3, float: "left", height:10 }}>
            {props.item.description}
          </Typography>
      </Grid>
  )
}

export function IntroSlider({}) {
  const [nextButtonText, setNextButtonText] = React.useState("Next")
  const [goToModel, setGoToModel] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const max_slide_index = 3
  const slides_list = [...Array(max_slide_index).keys()]

  const handleClick = () => {
    if(currentIndex===max_slide_index){setGoToModel(true)}
    setCurrentIndex(currentIndex+1)
  }

  React.useEffect(() => {
    if (slides_list.includes(currentIndex)) {
      setNextButtonText('Next') } else { setNextButtonText('Try the Model')}
  });

  var  items = [
    {
        name: "Affiniti Beta",
        description: <p>A conversational AI model that tries to offer support to people with their wellbeing and mental health.</p>,
    },
    {
      name: "Affiniti Beta",
      description: <p>This is a free preview for research and demo purposes. Our goal is to gather external feedback in order to improve our system and make it safer.</p>,
    },
    {
      name: "Affiniti Beta",
      description: <p>Affiniti Beta was trained to hold therapy-style conversations on a range of difficult topics, such as work-related stress, relationship issues, social anxiety, etc.</p>,
    },
    {
      name: "Disclaimer",
      description: <p>Affiniti Beta may generate incorrect, misleading, or offensive information. It is not currently intended for giving advice. If you are experiencing any mental health issues then seek professional support by contacting a licenced therapist, your GP or the <a href="https://www.nhs.uk/service-search/mental-health/find-an-urgent-mental-health-helpline" target="_blank">NHS Urgent Mental Health hotline</a>.</p>,
    }
  ]
  
  return (
    <Grid>
      {
        goToModel ? <Redirect to='/chat' />:
      <Grid>
      <Carousel
        //changeOnFirstRender = {true}
        navButtonsAlwaysInvisible = {true}
        autoPlay = {false}
        //animation = "slide"
        duration = "1000"
        indicatorIconButtonProps={{
          style: {
            marginTop: "100px",
            marginLeft: "13px",
            float: "left",
            color: SLIDER_INDICATOR_COLOR
          }}}
        activeIndicatorIconButtonProps={{
          style: {
            color: SLIDER_ACTIVE_INDICATOR_COLOR
          }}}
        index={currentIndex}
        onChange={ (active) => {setCurrentIndex(active)} }
        >
        {
          items.map((item, i) => <Item key={i} item={item} />)
        }
      </Carousel>
      <Button
        data-testid="next-button"
        style={{textTransform: 'none'}}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 3, backgroundColor: PRIMARY_COLOR, height: "40px", width: "20%", float: "left" }}
        onClick={() => {handleClick()}
        }
      >
        {nextButtonText}
      </Button>
    </Grid>
    }
    </Grid>
    );
  }
