import * as React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useHistory } from 'react-router-dom';
import Mobile from '../Mobile/index';
import Divider from '@mui/material/Divider';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';
import {
  setLoginStatus,
  setNotificationMsg,
  setUser
} from '../../redux/User/user.action';

import {
  PRIMARY_COLOR
} from '../../constant';
import { useLocation } from 'react-router-dom';

function SignInContainer({ setLoginStatus, isSuccessfullLogin, setNotificationMsg, notificationMsg, setUser }) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [openActivateEmail, setOpenActivateEmail] = React.useState(false);
  const [openActivateSuccess, setOpenActivateSuccess] = React.useState(true);
  const [isValidToken, setIsValidToken] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const location = useLocation();
  const state = location.state
  const history = useHistory();
  const params = new URLSearchParams(location.search)
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
    console.log("isMobileDevice: ", isMobileDevice);
  }, []);
  
  React.useEffect(()=>{

    // Check if the current page was reached directly or from a successful registration
    if (state !== undefined && state.needs_activation) {
      setOpenActivateEmail(true)
      history.replace({ ...history.location, state: undefined });
    }
    // handle when the url contains account activation token
    if (params && params.get("t")) {
      setIsLoading(true)
      const activation_token = params.get("t")
      queryAPI('/activate-account', {activation_token: activation_token}, 'POST')
        .then(response => {
          if (response && response.status === 200) {
            setIsValidToken(true)
          }
          setIsLoading(false)
        })
    }
  },[])


  React.useEffect(()=>{
    localStorage.clear();
  },[])
  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    setLoginStatus(true);
    const data = new FormData(event.currentTarget);
    const user_data = {
      email: data.get('email'),
      password: data.get('password'),
    }
    queryAPI('/token', user_data, 'POST')
      .then(response => {
        if (response.response && 
          [400, 401, 409].includes(response.response.status)) {
            setNotificationMsg("The username or password is incorrect")
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response.response && 
          response.response.status === 403) {
            setNotificationMsg("The account has not been activated yet")
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response.response && 
          response.response.status !== 200) {
            if (response.message) {setNotificationMsg(response.message)}
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response && 
          response.status === 200) {
            localStorage.setItem('auth_token', response.data.token);
            setUser(response.data)
            setIsLoggedIn(true)
            setLoginStatus(true)
        } else {
          setNotificationMsg("login failed")
          setIsLoggedIn(false)
          setLoginStatus(false)
        }
        setIsLoading(false)
      })
  };
  const handleClose = () => {
    setOpenActivateEmail(false);
  };
  const handleCloseActivation = () => {
    setOpenActivateSuccess(false);
  };
  return (
    <Grid container style={{ minHeight: '100vh' }}>
      {
        isMobile ? 
        <Mobile /> :
    
    <Grid container style={{ minHeight: '100vh' }}>
      {
        isLoggedIn && <Redirect to='/intro' />
      }
      {!isSuccessfullLogin &&
        <Notification
          severity="error"
          isOpen={true}
          message={notificationMsg} />
      }

      {params && params.get("t") && isValidToken &&
        <Snackbar 
          open={openActivateSuccess} 
          sx={{ width: '90%' }} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        >
          <Alert 
            onClose={handleCloseActivation} 
            severity="success" 
            sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', fontSize: '15px' }}
          >
            Your account has been successfully activated.
          </Alert>
        </Snackbar>
      }
      {params && params.get("t") && !isValidToken &&
        <Notification
          severity="error"
          isOpen={true}
          message="Invalid activation token" />
      }
      
      {openActivateEmail &&
        <Snackbar 
          open={openActivateEmail} 
          sx={{ width: '90%' }} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        >
          <Alert 
            onClose={handleClose} 
            severity="info" 
            sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', fontSize: '15px' }}
          >
            Please verify your email address by clicking on the link we've sent to your inbox to activate your account.
          </Alert>
        </Snackbar>
      }

      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ marginLeft: '15%', float: 'left', marginTop: '5%' }}>
              <img src="/images/affiniti.svg"
                width="90%"
                alt="affinti_logo"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 18, ml: 15, mr: 6 }}>
              <Typography
                variant="h2"
                sx={{ float: "left" }}
              >
                Login
              </Typography>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <img src="/images/login polygon.svg"
                  width="12%"
                  alt="affinti_logo"
                />
              </div>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2" sx={{ float: "right" }}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <LoadingButton
                data-testid = 'loginSubmitButton'
                type="submit"
                fullWidth
                variant="outlined"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: "white", height: "45px",
                  borderColor: PRIMARY_COLOR, color: PRIMARY_COLOR,
                  borderWidth: 2, 
                  '&:hover': {
                    backgroundColor: PRIMARY_COLOR,
                    color: "white"
                  } }}
              >
                Login
              </LoadingButton>
              <Divider>Or</Divider>
              <LoadingButton
                data-testid = 'signUpButton'
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px" }}
                onClick={() => { history.push('/register') }}
              >
                Sign Up
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}
        style={{ backgroundImage: `url("/images/login_background.png")`, backgroundSize: 'cover' }}
      >
      </Grid>
    </Grid>}
    </Grid>
  );
}

SignInContainer.propTypes = {
  isSuccessfullLogin: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ]),
  setLoginStatus: PropTypes.func,
  setNotificationMsg: PropTypes.func,
  notificationMsg: PropTypes.string,
  setUser: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    isSuccessfullLogin: state.user.isSuccessfullLogin,
    notificationMsg: state.user.notificationMsg,
  }
}

const mapDispatchToProps = {
  setLoginStatus,
  setNotificationMsg,
  setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);
