import * as React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';
import {
  setNotificationMsg,
} from '../../redux/User/user.action';

import {
  PRIMARY_COLOR
} from '../../constant';

function ForgotPasswordContainer({ setNotificationMsg, notificationMsg }) {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isSuccessSubmit, setIsSuccessSubmit] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [value, setValue] = React.useState('');

  let history = useHistory();
  function handleClickLogo(){
    history.push("/login")}

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    setIsSubmitted(true);
    setValue('');
    const data = new FormData(event.currentTarget);
    const user_data = {
      email: data.get('email'),
    }
    queryAPI('/forgot-password', user_data, 'POST')
      .then(response => {
        if (response && response.status == 200) {
          setIsSubmitted(true)
          setIsSuccessSubmit(true)
        } else if (response.response && [400, 404].includes(response.response.status)) {
          setIsSubmitted(true)
          setIsSuccessSubmit(true)
        } else {
          setNotificationMsg("Reset failed")
          setIsSubmitted(true)
          setIsSuccessSubmit(false)
          setTimeout(() => {setIsSubmitted(false)}, 2000);
        }
        setIsLoading(false)
      })
  };
  return (
    <Grid container style={{ minHeight: '100vh' }}>
      {isSubmitted && !isSuccessSubmit &&
        <Notification
          severity="error"
          isOpen={true}
          message={notificationMsg || "Reset Failed"} />
      }
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ marginLeft: '15%', float: 'left', marginTop: '5%' }}>
              <img src="/images/affiniti.svg"
                width="90%"
                alt="affinti_logo"
                onClick={handleClickLogo}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 18, ml: 15, mr: 6 }}>
              <Typography
                variant="h2"
                sx={{ float: "left" }}
              >
                Forgot Password
              </Typography>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <img src="/images/login polygon.svg"
                  width="12%"
                  alt="affinti_logo"
                />
              </div>

              {
                isSubmitted && isSuccessSubmit && !isLoading &&
                <Typography
                  variant="string"
                  sx={{ color:"green", float: "left", paddingTop:"30px", paddingBottom:"10px", textAlign:"left" }}
                >
                  <p>Please check your inbox.</p>
                  <p>If the email address you have provided is correct, an email will be sent to you with instructions on how to start the password reset process.</p>
                </Typography>
              }
              {
                (!isSubmitted || !isSuccessSubmit) &&
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue.target.value);
                }}
              />}
              {
                (!isSubmitted || !isSuccessSubmit) &&
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px" }}
              >
                Send Email
              </LoadingButton>}
              {
                isSubmitted && isSuccessSubmit && !isLoading &&
                <Grid container>
                  <Grid item>
                    <Link href="/forgot-password" variant="body2">
                      {"Didn't get the email?"}
                    </Link>
                  </Grid>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}
        style={{ backgroundImage: `url("/images/login_background.png")`, backgroundSize: 'cover' }}
      >
      </Grid>
    </Grid>
  );
}

ForgotPasswordContainer.propTypes = {
  setNotificationMsg: PropTypes.func,
  notificationMsg: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  return {
    notificationMsg: state.user.notificationMsg,
  }
}

const mapDispatchToProps = {
  setNotificationMsg,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
