import * as React from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import AppBarMenu from '../AppBarMenuContainer';

import { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constant';

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const drawerWidth = 280;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function BackToTop(props) {
  let history = useHistory();
  function handleClickLogo(){
    history.push("/intro")}
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar sx={{ paddingLeft:5, paddingRight:5, bgcolor: SECONDARY_COLOR }} data-testid="app-bar">
        <Toolbar>
          <img src="/images/affiniti.svg" 
            width={"8%"} 
            style={{ cursor: "pointer" }} 
            alt="affinti_logo" 
            onClick={handleClickLogo} />
          <div style={{ marginLeft: "82%", marginRight: '10px', display: "flex", cursor: "pointer" }}>
              <AppBarMenu />
          </div>
          <Tooltip title="logout" data-testid="logout-button">
            <IconButton
              sx={{ color: PRIMARY_TEXT_COLOR }}
              color="inherit"
              onClick={() => props.logout()}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {
        !props.isDashBoardPage &&
        <React.Fragment>
          <Toolbar id="back-to-top-anchor" />
          <ScrollTop {...props}>
            <Fab color="secondary" size="small" aria-label="scroll back to top"
              sx={{ bgcolor: PRIMARY_COLOR }}>
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

BackToTop.propTypes = {
  logout: PropTypes.func,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  isDashBoardPage: PropTypes.bool,
}