import LoginForm from "./Pages/Login/index";
import ResetPassForm from "./Pages/ResetPassword/index";
import ForgotPassForm from "./Pages/ForgotPassword/index";
import RegisterForm from "./Pages/SignUp/index";
import Intro from "./Pages/Intro";
import Chat from "./Pages/Chat";
import Profile from "./Pages/Profile";

const dashboardRoutes = [
    {
      path: "/register",
      name: "register",
      component: RegisterForm
    },  
    {
      path: "/login",
      name: "login",
      component: LoginForm
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassForm
    },
    {
      path: "/forgot-password",
      name: "forgot Password",
      component: ForgotPassForm
    },
    {
      path: "/intro",
      name: "intro",
      component: Intro
    },
    {
      path: "/chat",
      name: "chat",
      component: Chat
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile
    },
  ];
  
  export default dashboardRoutes;
  