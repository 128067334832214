import React from 'react';
import Grid from '@mui/material/Grid';

export default function Mobile() {

    return (
        <Grid item 
          xs={12} sm={12} md={12} lg={12} xl={12} 
          style={{ 
            textAlign: 'center', 
            margin: 'auto', 
            backgroundImage: `url("/images/background.png")`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center' 
            }}
        >

          <div 
            style={{ 
              backgroundColor: 'white', 
              width: '100%', 
              padding: '20px 0', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center' 
              }}
          >
            <img 
              src="/images/affiniti.svg" 
              alt="logo" 
              style={{ width: '100px', height: '100px', marginTop: '-40px', marginBottom: '-40px' }} 
            />
          </div>
          <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              flex: 1, 
              paddingLeft: '20px', 
              paddingRight: '20px'
            }}
          >
            <h1 style={{ color: 'white', marginTop: '20px' }}>
              This app is optimized for desktop and tablet devices only.
            </h1>
            <p style={{ color: 'white' }}>
              Please access the app from a larger screen device.
            </p>
          </div>
        </Grid>
    );
}
