import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../../config';
import Mobile from '../Mobile/index';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR
} from '../../constant';

export default function SignUp() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessRegister, setIsSuccessRegister] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isRecaptchaProblem, setRecaptchaProblem] = React.useState(false)
  const [isConfirmPassProblem, setConfirmPassProblem] = React.useState(false)
  const recaptchaRef = React.createRef();
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
    console.log("isMobileDevice: ", isMobileDevice);
  }, []);

  function is_registered(payload) {
    var status = JSON.parse(JSON.stringify(payload))["status"]
    if (status == 200) {
      setTimeout(() => {
        setIsSuccessRegister(true)
        setIsLoading(false)
        setIsSubmitted(true)
      }, 200)
    }
    else {
      setIsLoading(false)
      setIsSubmitted(true)
      document.getElementById("email").value = "";
      setTimeout(() => {setIsSubmitted(false)}, 2000);
    }
  }

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      console.log("recaptcha problem")
      setRecaptchaProblem(true)
      setIsLoading(false)
      return;
    }

    if (password !== confirmPassword) {
      console.log("Passwords do not match!")
      setConfirmPassProblem(true)
      setIsLoading(false)
      return;
    } else {setConfirmPassProblem(false)}

    const data = new FormData(event.currentTarget);
    const user_data = {
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      organisation: data.get('organisation'),
      password: data.get('password'),
      recaptcha_value: recaptchaValue,
    }
    queryAPI('/user', user_data, 'POST')
      .then(payload => {
        is_registered(payload)
      }
      )
  };
  function onChangeRecaptcha(value) {
    console.log("Recaptcha clicked.");
    setRecaptchaProblem(false)
  }
  
  return (
    <Grid container style={{ minHeight: '100vh' }}>
      {
        isMobile ? 
        <Mobile /> :
    
    <Grid container style={{ minHeight: '100vh' }}>

      {isRecaptchaProblem &&
        <Notification isOpen={true} severity="error" message={"Please confirm you are not a robot"} />
      }
      {isConfirmPassProblem &&
        <Notification isOpen={true} severity="error" message={"Passwords do not match!"} />
      }
      {isSuccessRegister &&
        <Notification isOpen={true} severity="success" message={"Successfully Registered"} />
      }
      {isSubmitted && !isSuccessRegister &&
        <Notification isOpen={true} severity="error" message={"The email is either invalid or already exists."} />
      }
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ marginLeft: '15%', float: 'left', marginTop: '5%' }}>
              <img src="/images/affiniti.svg"
                width="90%"
                alt="affinti_logo"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 18, ml: 15, mr: 6 }}>
              <Typography
                variant="h2"
                sx={{ float: "left" }}
              >
                Register
              </Typography>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <img src="/images/login polygon.svg"
                  width="12%"
                  alt="affinti_logo"
                />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="firstName"
                    name="first_name"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstname"
                    label="First name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="lastName"
                    variant="outlined"
                    required
                    fullWidth
                    id="lastname"
                    label="Last name"
                    name="last_name"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="organisation"
                    label="Organisation"
                    name="organisation"
                    autoComplete="organisation"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    aria-label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: 
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmpassword"
                    label="Retype Password"
                    aria-label="Retype Password"
                    type='password'
                    id="confirmpassword"
                    value={confirmPassword}
                    error={(confirmPassword && confirmPassword !== password) ? true : false}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    helperText="The two passwords should match"
                  />
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_KEY}
                    onChange={onChangeRecaptcha}
                    ref={recaptchaRef}
                  />
                </Grid>
              
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                color="primary"
                style={{ borderRadius:4, marginTop: 15, marginBottom: 2, backgroundColor: PRIMARY_COLOR, height: "45px", color: SECONDARY_COLOR }}
              >
                Sign Up
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2" sx={{ float: "right" }}>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}
        style={{ backgroundImage: `url("/images/login_background.png")`, backgroundSize: 'cover' }}
      >
      </Grid>
      {
        isSubmitted && isSuccessRegister &&
        <Redirect to={{ pathname: '/login', state: { needs_activation: true } }} />
      }
    </Grid>}
    </Grid>
  );
}
